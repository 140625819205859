import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";
import Modal from "react-modal";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

Modal.setAppElement(`#___gatsby`);

const PhotoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "photos" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  // Modal stuff.
  const [selectedImage, setSelectedImage] = useState<IGatsbyImageData | undefined>(undefined);
  const closeModal = () => {
    setSelectedImage(undefined);
  };

  const openImage = (image: IGatsbyImageData) => {
    setSelectedImage(image);
  };

  return (
    <Layout>
      <SEO title="Silvercast | Photos" />
      <div className="text-xl">
        <div className="flex flex-wrap">
          {data.allFile.edges.map(i => (
            <div
              className="p-4 xs:w-full sm:w-full md:w-1/2 lg:w-1/4"
              onClick={() => openImage(i.node.childImageSharp.gatsbyImageData)}
              key={i.node.relativePath}
            >
              <GatsbyImage image={i.node.childImageSharp.gatsbyImageData} alt={`Silvercast | ${i.node.name}`} />
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={selectedImage !== undefined}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.58)",
          },
          content: {
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
            left: "auto",
            right: "auto",
            bottom: "auto",
            maxWidth: "960px",
            margin: "auto auto",
            padding: 0,
            border: 0,
            backgroundColor: "transparent",
          },
        }}
      >
        {selectedImage && <GatsbyImage image={selectedImage} alt={`Silvercast | Photos`} />}
      </Modal>
    </Layout>
  );
};

export default PhotoPage;
